import React from 'react'
import Navbar from 'components/Navbar'
import logo from '../assets/logo2.png'
import Footer from 'components/Footer'


function ContactPage() {
  return (
    <div>
        <Navbar color="text-primary" logo={logo} page="contact"/>
        <div className='flex flex-col justify-center items-center'>
            <h1 className=' text-5xl text-primary font-primary'>Contact Us</h1>
            <div className='flex flex-col justify-center items-center'>
                <div className='flex flex-col lg:flex-row space-y-10 lg:space-y-0 items-center p-10'>
            <div className='w-[50%]'>
              <p>
                <strong className='text-2xl text-primary font-primary'>Address:</strong>
                <p className='font-secondary lg:w-[80%] py-4 text-lg'>
                Yeka Subcity, Woreda07, Near Signal Square, Tsega Bldg(Fresh corner Bldg), 7th Floor House No. 709, Office No. 701
      Addis Ababa, Ethiopia
                </p>
              </p>
              <p>
                <strong className='text-2xl text-primary font-primary'>Email:</strong> <a href='mailto:sales@vascometh.com' className='text-lg text-primary'>sales@vascometh.com</a>
              </p>
              <p>
                <strong className='text-2xl text-primary font-primary'>Website:</strong> <a href='www.vascometh.com' className='text-lg text-primary'>www.vascometh.com</a>
              </p>
              <p>
                <strong className='text-2xl text-primary font-primary'>Phone:</strong> <a className='text-lg text-primary'>+251910587873</a>
              </p>
            </div>
            <div className=''>
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1117.95155208194!2d38.757346263579336!3d8.980732127214141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b8558f110f351%3A0x589c96a1da2ee7a5!2sVascom%20Engineering%20PLC!5e0!3m2!1sen!2set!4v1664267853626!5m2!1sen!2set" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.4435802026296!2d38.781508599999995!3d9.0232372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b857c576cb037%3A0xa2c6bdf1d2664d96!2zRnJlc2ggQ29ybmVyIHwgU2lnbmFsIHwg4Y2N4Yis4Yi9IOGKruGIreGKkOGIrSB8IOGIsuGMjeGKk-GIjQ!5e0!3m2!1sen!2set!4v1720766516695!5m2!1sen!2set" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" aria-hidden="false" tabIndex="0"/>
            </div>
        </div>
        <div className='w-[50%] py-10'>
            <form className="contact__data__form flex flex-col font-primary items-center gap-[2rem]" action='https://formsubmit.co/cdc19326455c814f0363f175a1bd1f11' method='POST'>
                <input type="text"name='Name' placeholder='name' required className='uppercase  border-b border-primary w-full text-primary pb-[0.7rem] focus:outline-none placeholder:text-primary'/>
                <input type="email" name='Email' placeholder='email' required className='uppercase  border-b border-primary w-full text-primary pb-[0.7rem] focus:outline-none placeholder:text-primary' />
                <textarea placeholder='message'name='Message' required className='uppercase  border-b border-primary w-full text-primary pb-[0.7rem] focus:outline-none placeholder:text-primary h-[50%] resize-none'></textarea>
                <button type='submit' className='w-full bg-transparent border border-primary h-[3rem] text-primary uppercase cursor-pointer transition ease-in-out hover:bg-primary hover:text-white'>Send Message</button>
            </form>
        </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default ContactPage