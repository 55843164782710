import React from "react";
import { useQuery, gql } from "@apollo/client";
import Loading from "components/Loading";
import { useScroll } from "components/useScroll";
import Navbar from "components/Navbar";
import logo from "../assets/logo2.png";
import Footer from "components/Footer";

const GALLERY_QUERY = gql`
  {
    galleries(first: 100) {
      edges {
        node {
          galleryMeta {
            image {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;

function GalleryPage() {
  const { data, loading, error } = useQuery(GALLERY_QUERY);
  const [element, controls] = useScroll();
  console.log(data);
  if (loading) return <Loading />;
  if (error) return <pre>{error.message}</pre>;

  const dataa = data.galleries.edges;
  return (
    <div className="">
      <div>
        <Navbar logo={logo} page="gallery" color="text-primary" />
      </div>
      <h1 className="text-center font-bold py-10 z-0 text-5xl font-primary text-primary">
        Projects Gallery
      </h1>

      <div className="container lg:px-32 px-4 py-8 mx-auto items-center ">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          {dataa.map((data) => (
            <div className="row-span-2 ">
              <img
                src={data.node.galleryMeta.image.sourceUrl}
                alt="Photo by Claudio Schwarz on Unsplash"
                className="inset-0 h-full w-full object-cover object-center rounded`` hover:opacity-100 w-full h-full transition duration-300 ease-in-out"
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default GalleryPage;
