import React from 'react'
import Navbar from 'components/Navbar'
import logo from '../assets/logo2.png'
import about from '../assets/about.jpg'
import Footer from 'components/Footer'

export default function AboutusPage() {
  return (
    <div className='text-justify'>
      <Navbar logo={logo} page='about' color='text-primary'/>
      <div className=' min-h-screen'>
        <div className='flex flex-col items-center justify-center space-y-10'>
        <h1 className='font-bold text-5xl text-primary font-primary'>About Us</h1>
        <div className='flex flex-col-reverse lg:flex-row'>
          <div className='lg:w-[50%]'>
            {/* About  */}
            <p className=' font-secondary p-10 text-lg text-justify text-primary'>
            Founded by ambitious Electrical Systems Engineering professionals with a paradigm of technology and service excellence is to realize in Ethiopia using indigenous knowledge base. The first objective of the company to give ICT and Power Solutions with discipline and commitment for customer demands of IT and Power Systems Engineering and design. The founders of the company have a long journey of experiences in the areas of Power Generation, Energy Demand Management, Power System, ICT and Telecom Sectors as designer, operation manager and Service Integrator. The accelerating demands to technology solutions and country’s economic growth has caused to conceive and borne the company to serve and alleviate the society’s day-to-day system engineering problems as well creating a room for similar professionals to serve the country as an employee and practitioner too. Last mile Goal, Engineering Excellence Delivered!
            </p>
          </div>

          <div className='bg-primary p-[0.1rem] lg:mr-10'>
            <img src={about} alt=""  className='w-full h-full object-cover'/>
          </div>
        </div>
        </div>
      </div>
      <div className='min-h-screen flex flex-col  space-y-9 lg:px-20'>
        {/* Mission */}
        <div className='md:flex md:space-x-10 p-10 items-center space-y-10 md:space-y-0'>
          <div>
            <h2 className='text-4xl text-primary font-medium font-primary bg-gray-200 rounded-xl p-6'>Mission</h2>
          </div>
          <div className='md:w-[50%] md:border-l-2 md:pl-10 font-p-lg'>
            <p className='font-secondary text-primary'>
            To respect the discipline of professionalism in Engineering Services throughout the lifetime of the company as an 
            icon and identity.
            </p>
          </div>
        </div>
        {/* Vision */}
        <div className='md:flex md:space-x-10 p-10 items-center md:flex-row-reverse space-y-10 md:space-y-0'>
          <div>
            <h2 className='text-4xl text-primary font-medium md:pl-10 font-primary bg-gray-200 rounded-xl p-6 mx-2' >Vision</h2>
          </div>
          <div className='md:w-[50%] md:border-r-2 md:pr-10 font-p-lg'>
            <p className='font-secondary text-primary'>
            VASCOM has a strong commitment to be one of the leading system engineering service providers in Ethiopia and 
            a while later in East Africa. The Company has acquired knowledge bank and experiences of different project on 
            turnkey basis as well consultancy. The company has long sight vision to be one of the leading technology 
            innovator and introducer.
            </p>
          </div>
        </div>

        {/* Goal */}
        <div className='md:flex md:space-x-10 p-10 items-center space-y-10 md:space-y-0'>
          <div>
            <h2 className='text-4xl text-primary font-medium font-primary bg-gray-200 rounded-xl p-6'>Values</h2>
          </div>
          <div className='md:w-[50%] md:border-l-2 md:pl-10 font-p-lg'>
            <p className='font-secondary text-primary'>
            One stop Technology Solution dedicated to customers demand and building customer experience. The expertise 
            knowledge base that we have built in our previous carriers’ development is our core value. We have many experts 
            in the country to be partner with our company as a freelance, employee, part time employee, and consultant or 
            on-board advice. The highly sensitive care for our clients has a special value the company has gained.
            </p>
          </div>
        </div>

        <div className='md:flex md:space-x-10 p-10 items-center md:flex-row-reverse space-y-10 md:space-y-0'>
          <div>
            <h2 className='text-4xl text-primary font-medium md:pl-10 font-primary bg-gray-200 rounded-xl p-6 mx-2' >Goal</h2>
          </div>
          <div className='md:w-[50%] md:border-r-2 md:pr-10 font-p-lg'>
            <p className='font-secondary text-primary'>
            Delivering engineering excellence through our innovative products, solutions and services .

            </p>
          </div>
        </div>

      </div>
      <Footer />
    </div>
  )
}
