import Navbar from "components/Navbar";
import React from "react";
import logo from "../assets/logo2.png";
import hero from "../assets/power/hero.jpg";
import Hybrid from "../assets/power/1.gif";
import SolarPump from "../assets/power/2.jpg";
import Hybridd from "../assets/power/3.jpg";
import Solar from "../assets/power/4.jpg";
import Roof from "../assets/power/5.jpg";
import Footer from "components/Footer";

function PowerandEnergy() {
  return (
    <div className="text-primary text-justify">
      <Navbar color="text-primary" logo={logo} page="services" />
      <div className="flex flex-col items-center">
        <h1 className="font-primary text-4xl lg:text-5xl text-primary">
          Power and Energy
        </h1>
      </div>
      <div className="w-full  pt-10">
        <img src={hero} alt="" className="w-screen h-[20rem] object-cover" />
      </div>
      <div className="p-10">
        <p className="text-xl p-2">
          Power and Energy sector the second core business we are giving
          services to our clients and the society. We develop solar energy farms
          for Remote Irrigation Sites, Universities, Rural Villages, NGOs,
          financial institutes, and residential houses. We have envisioned the
          energy access to all household’s strategy of FDRE to play our maximum
          contribution to develop renewable and clean energy as well to reach to
          the rural depth of Ethiopia to lit at least a bulb.
        </p>
      </div>
      <div className="xl:px-32 xl:py-10">
        <div>
          <h2 className="font-primary text-primary text-2xl font-semibold pl-10 py-4">
            1. Hybrid Solar Power System
          </h2>
          <div className="lg:flex lg:w-full lg:items-center ">
            <div>
              <img
                src={Hybrid}
                alt=""
                className="lg:h-[25rem] lg:w-[50rem] lg:object-cover object-contain lg:px-10"
              />
            </div>
            <p className="lg:w-[50rem] lg:px-10 mx-10 text-lg pt-9 lg:pt-0">
              Free, clean, and environmentally friendly energy sources are the
              global trends of energy development and access.
              <br />
              <br />
              We design hybrid power solution with maximum energy conversion
              from the sun and storing it into Lithium ion or GEL battery packs.
              We serve our customers from design to deploying the power using
              the sun and the wind as prime source.
              <br />
              <br />
              We use inverters designed for maximum power point tracking (MPPT)
              to collect solar energy at peak sun-hour of the day.
              <br />
              <strong>Preference:</strong> it is easy to use and maintenance
              free for any application and the system is fully automatic in any
              event of power outage or restoration.
              <br />
              <br />
              Applications: Data centers, Residential Houses, Banks branch
              operation, remote area irrigation and potable water pumping
              stations, city malls.
            </p>
          </div>
        </div>

        <div className="mt-7 mb-3">
          <h2 className="font-primary text-primary text-2xl font-semibold pl-10 py-4">
            2. Solar Lanterns and solar pumps for Rural Villages
          </h2>
          <div className="lg:flex lg:w-full lg:items-center lg:flex-row-reverse ">
            <img
              src={SolarPump}
              alt=""
              className="lg:h-[25rem] lg:w-[50rem] object-contain "
            />
            <p className="lg:w-[50rem] lg:px-10  text-lg pt-9 lg:pt-0">
              We supply and install solar lanterns and solar water pumps. Solar
              lanterns will support TV and radio power, mobile charger, torch
              cell charger, set of ceiling suspense type LED bulbs with
              respective switch buttons for the mass rural community
            </p>
          </div>
        </div>

        <div>
          <h2 className="font-primary text-primary text-2xl font-semibold pl-10 py-4">
            3. Solar compact lithium street and compound lighting solution{" "}
          </h2>
          <div className="lg:flex lg:w-full lg:items-center ">
            <div>
              <img
                src={Solar}
                alt=""
                className="lg:h-[25rem] lg:w-[50rem] lg:object-cover object-contain lg:px-10"
              />
            </div>
            <p className="lg:w-[50rem] lg:px-10 mx-10 text-lg pt-9 lg:pt-0">
              Solar compact lithium street and compound lighting solution is the
              trending application for Univerities, Hospitals, Markets centers
              and city roads
            </p>
          </div>
        </div>

        <div className="mt-7 mb-3">
          <h2 className="font-primary text-primary text-2xl font-semibold pl-10 py-4">
            4. Developement of rooftop solar farms
          </h2>
          <div className="lg:flex lg:w-full lg:items-center lg:flex-row-reverse ">
            <img
              src={Roof}
              alt=""
              className="lg:h-[25rem] lg:w-[50rem] lg:object-cover object-contain lg:px-10"
            />
            <p className="lg:w-[50rem] lg:px-10 mx-10 text-lg pt-9 lg:pt-0">
              Developement of rooftop solar farms to serve corporate offices
              with free and clean energy. Rooftop areas are suitable to harvest
              free energy for data centers , water pumps.computers printers, and
              lighting loads. The solar energy can be collected and stored in
              the latest Lithium battery packs of longer lifetime of 7 to 10
              years
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PowerandEnergy;
