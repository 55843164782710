import React from "react";
import Navbar from "components/Navbar";
import logo from "../assets/logo2.png";

import { useQuery, gql } from "@apollo/client";
import Loading from "components/Loading";
import { servicesAnimations } from "animation";
import { useScroll } from "components/useScroll";
import { motion } from "framer-motion";
import Footer from "components/Footer";
import { Link } from "react-router-dom";
import ServiceImage from "../assets/services/1.jpg";
import DataCenterImage from "../assets/services/2.jpg"

const Serivece_QUERY = gql`
  {
    services {
      edges {
        node {
          serviceMeta {
            title
            description
            image {
              sourceUrl
            }
            type
          }
        }
      }
    }
  }
`;

function ServicesPage() {
  const { data, loading, error } = useQuery(Serivece_QUERY);
  const [element, controls] = useScroll();
  if (loading) return <Loading />;
  if (error) return <pre>{error.message}</pre>;

  const dataas = data.services.edges;
  return (
    <div className="">
      <Navbar logo={logo} page="services" color="text-primary" />
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-5xl text-primary font-primary">Our Services</h1>

        <div className="my-10 space-y-20">
          <div className=" flex flex-col items-center justify-center">
            <Link
              to="/services/ict"
              className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm"
              role="menuitem"
              tabindex="-1"
              id="menu-item-0"
            >
              <div className="text-primary font-primary text-4xl pl-10">
                ICT Solutions
              </div>
            </Link>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 p-10 ">
              <motion.div
                className="services__service"
                // variants={servicesAnimations}
                animate={controls}
                transition={{
                  delay: 0.03,
                  type: "tween",
                  duration: 0.8,
                }}
              >
                <div className="services__service__image ">
                  <img
                    src={ServiceImage}
                    alt="Service "
                    className="w-full h-[200px] object-cover"
                  />
                </div>

                <div className="text-3xl text-primary py-9 font-primary">
                  <h2>Networking & Solutions</h2>
                </div>
                <p className="text-justify services__service__description font-secondary text-primary">
                Turnkey solutions over design and deployment of :
                  <ul className="pl-5">
                    <li>
                      <span className="text-xl font-extrabold">·</span> Wireless and wired network infrastructure
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> Local area and wide area networks
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> Fiber and copper backbone networks
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> Unified communication 
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> IP PABX
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> Video Conferencing 
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> Smart class solution
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> Modern auditorium solution
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> CCTV
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> Integrated Access Control 
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> Parking Management 
                    </li>
                  </ul>
                  for various institutions such as educational sectors, Banks, Hospitals, Development and private organizations. Network Infrastructure solutions with us will leverage with highly available and secured infrastructure.
                </p>
              </motion.div>
              
              <motion.div
                className="services__service"
                // variants={servicesAnimations}
                animate={controls}
                transition={{
                  delay: 0.03,
                  type: "tween",
                  duration: 0.8,
                }}
              >
                <div className="services__service__image ">
                  <img
                    src={DataCenterImage}
                    alt="Service "
                    className="w-full h-[200px] object-cover"
                  />
                </div>

                <div className="text-3xl text-primary py-9 font-primary">
                  <h2>Data Center Solution</h2>
                </div>
                <p className="text-justify services__service__description font-secondary text-primary">
                Turnkey projects on data center solution including :
                  <ul className="pl-5">
                    <li>
                      <span className="text-xl font-extrabold">·</span> Server computing environment
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> Storage
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> Switching and routing
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> Racks and containment
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> Security
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> Power and cooling(HVAC –precision  system) 
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> Fire and smoke detection/suppression
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> DC secure door, access control and CCTV
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> Data center fire rated works(wall, ceiling and raised floors works) including decoration
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> Data center information Management(DCIM)
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> Disaster recovery(DR) site preparation 
                    </li>
                    <li>
                      <span className="text-xl font-extrabold">·</span> Cloud and shared infrastructure building
                    </li>
                  </ul>
                </p>
              </motion.div>
              {/* {dataas.map((service,index) => {
                        if(service.node.serviceMeta.type === 'ICT Solutions'){

                        return (
                        <motion.div className="services__service"
                        // variants={servicesAnimations}
                        animate={controls}
                        transition={{
                            delay: 0.03,
                            type: "tween",  
                            duration: 0.8,
                        }}
                        >
                            <div className="services__service__image ">
                                <img src={  service.node.serviceMeta.image.sourceUrl} alt="Service " className='w-full h-[200px] object-cover' />  
                            </div>
                            <div className="text-3xl text-primary py-9 font-primary">
                                <h2>{service.node.serviceMeta.title}</h2>
                            </div>
                            <p className="text-justify services__service__description font-secondary text-primary">{service.node.serviceMeta.description}</p>
                        </motion.div>
                        )
                      }
                    })}  */}
            </div>
          </div>

          {/* POWER  */}
          <div className=" flex flex-col items-center justify-center">
            <Link
              to="/services/power"
              className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm"
              role="menuitem"
              tabindex="-1"
              id="menu-item-0"
            >
              <div className="text-primary font-primary text-4xl pl-10">
                Power and Energy Solutions
              </div>
            </Link>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 p-10 ">
              {dataas.map((service, index) => {
                if (
                  service.node.serviceMeta.type === "Power and Energy Solutions"
                ) {
                  return (
                    <motion.div
                      className="services__service"
                      // variants={servicesAnimations}
                      animate={controls}
                      transition={{
                        delay: 0.03,
                        type: "tween",
                        duration: 0.8,
                      }}
                    >
                      <div className="services__service__image ">
                        <img
                          src={service.node.serviceMeta.image.sourceUrl}
                          alt="Service "
                          className="w-full h-[300px] object-cover"
                        />
                      </div>
                      <div className="text-3xl text-primary py-9 font-primary">
                        <h2>{service.node.serviceMeta.title}</h2>
                      </div>
                      <p className="text-justify services__service__description font-secondary">
                        {service.node.serviceMeta.description}
                      </p>
                    </motion.div>
                  );
                }
              })}
            </div>
          </div>

          {/* Electero */}
          <div className=" flex flex-col items-center justify-center">
            <Link
              to="/services/electromechanical"
              className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm"
              role="menuitem"
              tabindex="-1"
              id="menu-item-0"
            >
              <div className="text-primary font-primary text-4xl pl-10">
                Electromechanical Solutions
              </div>
            </Link>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 p-10 ">
              {dataas.map((service, index) => {
                if (
                  service.node.serviceMeta.type ===
                  "Electromechanical Solutions"
                ) {
                  return (
                    <motion.div
                      className="services__service"
                      // variants={servicesAnimations}
                      animate={controls}
                      transition={{
                        delay: 0.03,
                        type: "tween",
                        duration: 0.8,
                      }}
                    >
                      <div className="services__service__image ">
                        <img
                          src={service.node.serviceMeta.image.sourceUrl}
                          alt="Service "
                          className="w-full h-[200px] object-cover"
                        />
                      </div>
                      <div className="text-3xl text-primary py-9 font-primary">
                        <h2>{service.node.serviceMeta.title}</h2>
                      </div>
                      <p className="text-justify services__service__description font-secondary">
                        {service.node.serviceMeta.description}
                      </p>
                    </motion.div>
                  );
                }
              })}
            </div>
          </div>

          {/* industrial service and trading solutions */}
          <div className=" flex flex-col items-center justify-center">
            <Link
              to="/services/electromechanical"
              className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm"
              role="menuitem"
              tabindex="-1"
              id="menu-item-0"
            >
              <div className="text-primary font-primary text-4xl pl-10">
                Industrial Service And Trading
              </div>
            </Link>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 p-10 ">
              {dataas.map((service, index) => {
                if (
                  service.node.serviceMeta.type ===
                  "industrial service and trading"
                ) {
                  return (
                    <motion.div
                      className="services__service"
                      // variants={servicesAnimations}
                      animate={controls}
                      transition={{
                        delay: 0.03,
                        type: "tween",
                        duration: 0.8,
                      }}
                    >
                      <div className="services__service__image ">
                        <img
                          src={service.node.serviceMeta.image.sourceUrl}
                          alt="Service "
                          className="w-full h-[300px] object-cover"
                        />
                      </div>
                      <div className="text-3xl text-primary py-9 font-primary">
                        <h2>{service.node.serviceMeta.title}</h2>
                      </div>
                      <p className="text-justify services__service__description font-secondary">
                        {service.node.serviceMeta.description}
                      </p>
                    </motion.div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ServicesPage;
