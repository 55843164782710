import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// import ApolloClient, { InMemoryCache } from 'apollo-boost'
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
// import {ApolloProvider} from 'react-apollo'

const client = new ApolloClient({
  uri: 'https://vascometh.com/api/graphql',
  cache: new InMemoryCache()
})

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

