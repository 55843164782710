import Contact from 'components/Contact';
import Footer from 'components/Footer';
import Home from 'components/Home';
import Milestones from 'components/Milestones';
import Portfolio from 'components/Gallery';
import Clients from 'components/Clients';
import ScrollToTop from 'components/ScrollToTop';
import Services from 'components/Services';
import Skills from 'components/Skills';
import Testimonials from 'components/Testimonials';
import Parteners from 'components/Partners';
import Chat from 'components/Chat';
// import op from 'react-optimized-image/plugin'

import { motion } from 'framer-motion';
import React from 'react';




function HomePage() {
  return (
      <motion.div initial="hidden" animate="show">
        <Home />
        <Services />
        <Parteners />
        {/* <Portfolio /> */}
        <Milestones />
        <Clients />
        <Testimonials />
        <Skills />
        <Contact />
        <Footer />
        <ScrollToTop />
        <Chat/>
      </motion.div>
  )
}

export default HomePage