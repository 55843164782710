import React, { useState } from 'react';
import styled from "styled-components";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { useScroll } from "components/useScroll";
import { motion } from "framer-motion";
import { navAnimation } from "animation";
import { Link } from 'react-router-dom';

import { useQuery, gql } from "@apollo/client"
import Loading from './Loading';


const LOGO_QUERY = gql`
  {
  logos{
    edges{
      node{
        logoMeta{
          logo{
            sourceUrl
          }
        }
      }
    }
  }
}
`

function Navbar({logo, page, color}) {
  const [show, setShow] = useState(false)

  const [element, controls] = useScroll();
  const [isNavOpen,setIsNavOpen] = useState(false);
  const { data, loading, error } = useQuery(LOGO_QUERY);
  if (loading) return <Loading />;
  if (error) return <pre>{error.message}</pre>


  const dataa = data.logos.edges[0].node.logoMeta.logo.sourceUrl;


  return <Nav ref={element}
  variants={navAnimation}
  transition={{ delay: 0.1 }}
  animate={controls} 
  state={isNavOpen ? 1 : 0}
  className = {` h-40`}
  >
    <div className="brand__container ">
      <a href="/" className='brand'>
        <img src={logo} alt="logo" className='w-[10rem]'/>
      </a>  
      <div className="toggle">
        {isNavOpen ? (
          <MdClose onClick={ () => setIsNavOpen(false)} />
        ) : (
          <GiHamburgerMenu
            onClick={ (e) => {
              e.stopPropagation();
              setIsNavOpen(true);
            }}
            className = {`${color}`}
            />
        )}
      </div>
    </div>
    <div className={`links ${isNavOpen ? "show" : ""} ${color}`}>
      {
        page==="home"?(

          <ul className='font-primary'>
            <li className="active">
              <Link to="/">
                Home
              </Link>   
              </li>
              <li>
              <Link to="/about">
              About Us
              </Link>
              </li>
              <li>
              <div className="relative inline-block items-center text-left">
            <div>
              <button type="button" className="flex items-center" id="menu-button" aria-expanded="true" aria-haspopup="true" onClick={()=>setShow(!show)}>
                Products and Services 
                {/* <!-- Heroicon name: mini/chevron-down --> */}
                {
                  show?(
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="-mr-1 ml-2 h-5 w-5">
                    <path fill-rule="evenodd" d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z" clip-rule="evenodd" />
              </svg>
                  ):(
                  <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                  </svg>
                  )
                }

              </button>
            </div>
            
            <div className={`absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none  ${show?(""):("hidden")}`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
              <div className="py-1" role="none">
                {/* <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" --> */}
                <Link to='/services/ict' className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">ICT and Data-Com</Link>
                <Link to="/services/power" className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">Power and Energy </Link>
                <Link to="/services/electromechanical" className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">Electromechanical Works</Link>
                <Link to="/services/sales" className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">Industrial and Sales Services</Link>
              </div>
            </div>
          </div>

              </li>
              <li>
              <Link to="/gallery">
                Gallery
              </Link>
              </li>
              <li>
              <Link to="/contact">
              Contact
              </Link>
              </li>
          </ul>
        ):(
            page === 'gallery'?(
              <ul>
                    <li>
                      <Link to="/">
                        Home
                      </Link>   
                      </li>
                      <li>
                      <Link to="/about">
                      About Us
                      </Link>
                      </li>
                      <li>
              <div className="relative inline-block items-center text-left">
            <div>
              <button type="button" className=" flex items-center" id="menu-button" aria-expanded="true" aria-haspopup="true" onClick={()=>setShow(!show)}>
                Products and Services 
                {/* <!-- Heroicon name: mini/chevron-down --> */}
                {
                  show?(
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="-mr-1 ml-2 h-5 w-5">
                    <path fill-rule="evenodd" d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z" clip-rule="evenodd" />
              </svg>
                  ):(
                  <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                  </svg>
                  )
                }

              </button>
            </div>
            
            <div className={`absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none  ${show?(""):("hidden")}`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
              <div className="py-1" role="none">
                {/* <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" --> */}
                <Link to='/services/ict' className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">ICT and Data-Com</Link>
                <Link to="/services/power" className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">Power and Energy </Link>
                <Link to="/services/electromechanical" className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">Electromechanical Works</Link>
                <Link to="/services/sales" className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">Industrial and Sales Services</Link>
              </div>
            </div>
          </div>

              </li>
                      <li className="active">
                      <Link to="/gallery">
                        Gallery
                      </Link>
                      </li>
                      <li>
                      <Link to="/contact">
                      Contact
                      </Link>
                      </li>
                </ul>
            ):(
              page === "about"?(
                <ul>
                    <li>
                      <Link to="/">
                        Home
                      </Link>   
                      </li>
                      <li className="active">
                      <Link to="/about">
                      About Us
                      </Link>
                      </li>
                      <li>
              <div className="relative inline-block items-center text-left">
            <div>
              <button type="button" className=" flex items-center" id="menu-button" aria-expanded="true" aria-haspopup="true" onClick={()=>setShow(!show)}>
                Products and Services 
                {/* <!-- Heroicon name: mini/chevron-down --> */}
                {
                  show?(
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="-mr-1 ml-2 h-5 w-5">
                    <path fill-rule="evenodd" d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z" clip-rule="evenodd" />
              </svg>
                  ):(
                  <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                  </svg>
                  )
                }

              </button>
            </div>
            
            <div className={`absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none  ${show?(""):("hidden")}`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
              <div className="py-1" role="none">
                {/* <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" --> */}
                <Link to='/services/ict' className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">ICT and Data-Com</Link>
                <Link to="/services/power" className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">Power and Energy </Link>
                <Link to="/services/electromechanical" className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">Electromechanical Works</Link>
                <Link to="/services/sales" className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">Industrial and Sales Services</Link>
              </div>
            </div>
          </div>

              </li>
                      <li>
                      <Link to="/gallery">
                        Gallery
                      </Link>
                      </li>
                      <li>
                      <Link to="/contact">
                      Contact
                      </Link>
                      </li>
                </ul>
              ):( 
                page==="services"?(
                <ul>
                    <li>
                      <Link to="/">
                        Home
                      </Link>   
                      </li>
                      <li>
                      <Link to="/about">
                      About Us
                      </Link>
                      </li>
                      <li className="active">
                    <div className="relative inline-block items-center text-left">
                  <div>
                    <button type="button" className="flex items-center" id="menu-button" aria-expanded="true" aria-haspopup="true" onClick={()=>setShow(!show)}>
                      Products and Services 
                      {
                        show?(
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="-mr-1 ml-2 h-5 w-5">
                          <path fill-rule="evenodd" d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z" clip-rule="evenodd" />
                    </svg>
                        ):(
                        <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                        </svg>
                        )
                      }

                    </button>
                  </div>
                  
                  <div className={`absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none  ${show?(""):("hidden")}`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                    <div className="py-1" role="none">
                      {/* <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" --> */}
                      <Link to='/services/ict' className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">ICT and Data-Com</Link>
                      <Link to="/services/power" className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">Power and Energy </Link>
                      <Link to="/services/electromechanical" className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">Electromechanical Works</Link>
                      <Link to="/services/sales" className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">Industrial and Sales Services</Link>
                    </div>
                  </div>
                </div>
              </li>
                      <li>
                      <Link to="/gallery">
                        Gallery
                      </Link>
                      </li>
                      <li>
                      <Link to="/contact">
                      Contact
                      </Link>
                      </li>
                </ul>
                ) :(
                  <ul>
                    <li>
                      <Link to="/">
                        Home
                      </Link>   
                      </li>
                      <li>
                      <Link to="/about">
                      About Us
                      </Link>
                      </li>
                      <li>
              <div className="relative inline-block items-center text-left">
            <div>
              <button type="button" className="flex items-center" id="menu-button" aria-expanded="true" aria-haspopup="true" onClick={()=>setShow(!show)}>
                Products and Services 
                {/* <!-- Heroicon name: mini/chevron-down --> */}
                {
                  show?(
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="-mr-1 ml-2 h-5 w-5">
                    <path fill-rule="evenodd" d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z" clip-rule="evenodd" />
              </svg>
                  ):(
                  <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                  </svg>
                  )
                }

              </button>
            </div>
            
            <div className={`absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none  ${show?(""):("hidden")}`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
              <div className="py-1" role="none">
                {/* <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" --> */}
                <Link to='/services/ict' className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">ICT and Data-Com</Link>
                <Link to="/services/power" className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">Power and Energy </Link>
                <Link to="/services/electromechanical" className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">Electromechanical Works</Link>
                <Link to="/services/sales" className="text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">Industrial and Sales Services</Link>
              </div>
            </div>
          </div>

              </li>
                      <li>
                      <Link to="/gallery">
                        Gallery
                      </Link>
                      </li>
                      <li className="active">
                      <Link to="/contact">
                      Contact
                      </Link>
                      </li>
                </ul>
                )
              )
            )
        )
      }
    </div>
  </Nav>
}

const Nav = styled(motion.nav)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
  /* color: #fff; */
  padding-top: 0.3rem;
  .brand__container {
    margin: 0 1rem;
    .toggle {
      display: none;
    }
  }
  .links {
    ul {
      list-style-type: none;
      display: flex;
      gap: 3rem;
      .active{
        
          border-bottom: 0.2rem solid var(--primary-color);
        
      }
      li {
        a {
          /* color: #fff; */
          text-decoration: none;
          font-weight: 400;
          font-size: 0.9rem;
          text-transform: uppercase;
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    margin: 0;
    position: relative;
    .brand__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .toggle {
        padding-right: 1rem;
        display: block;
        z-index: 1;
      }
    }
    .show {
      opacity: 1 !important;
      visibility: visible !important;
    }
    .links {
      position: absolute;
      overflow-x: hidden;
      top: 0;
      right: 0;
      width: ${({ state }) => (state ? "100%" : "0%")};
      height: 100vh;
      background-color: var(--secondary-color);
      opacity: 0;
      visibility: hidden;
      transition: 0.4s ease-in-out;
      ul {
        flex-direction: column;
        text-align: center;
        height: 100%;
        justify-content: center;
      }
    }
  }
`;

export default Navbar;