import React, { useState } from 'react';
import loadmore from "assets/loadmore.png";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useScroll } from "./useScroll";
import { testimonialsAnimations } from "animation";
import  Loading from './Loading'

import { useQuery, gql } from "@apollo/client";

const TESTIMONIAL_QUERY = gql`
{
testimonials{
  edges{
    node{
      testimonialMeta{
        fullName,
        testimony,
        title
      }
    }
  }
}
}
` 

function Testimonials() {
  const [element, controls] = useScroll();
  const [selected, setSelected] = useState(0);

  const { data, loading, error } = useQuery(TESTIMONIAL_QUERY);
  if (loading) return <Loading />;
  if (error) return <pre>{error.message}</pre>

  const testimonialsList = data.testimonials.edges

  return (
    <Section ref={element} className = "z-10 flex flex-col items-center">
      <div className="container">
        <motion.div className="testimonials"
        variants={testimonialsAnimations}
        animate={controls}
        transition={{
          delay: 0.03,
          type: "tween",
          duration: 0.8,
        }}
        >
          {
            testimonialsList.map((testimonial, index)=> {
              return (
                <div className={`testimonial ${selected === index ? "active" : "hidden"}`} key={index}>
                  <div className="image">
                    <div className="circle2">
                      <img src={loadmore} alt="Loadmore" />
                    </div>
                  </div>
                  <div className="title-container">
                    <span className="designation font-primary">
                      {testimonial.node.testimonialMeta.title}
                    </span>
                    <h3 className='title font-primary'>{testimonial.node.testimonialMeta.fullName}</h3>
                  </div>
                  <p className="description font-secondary">{testimonial.node.testimonialMeta.testimony}</p>
                </div>
              );
            })
          }
        </motion.div>
        <motion.div className="controls"
        variants={testimonialsAnimations}
        animate={controls}
        transition={{
          delay: 0.03,
          type: "tween",
          duration: 0.8,
        }}
        >
          {
            testimonialsList.map((testimonial, index)=>{
              return(
                <button
                  className={selected === index ? "active" : ""}
                  onClick={() => {
                    setSelected(index);
                  }}>
                </button>
              )
            })
          }

        </motion.div>
      </div>
    </Section>
  )
}

const Section = styled.section`
overflow: hidden;
background-color: var(--primary-color);

.container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  .testimonials {
    display: flex;
    gap: 1rem;
    text-align: center;
    justify-content: center;
    width: 30%;
    .testimonial {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      .image {
        position: relative;
        .circle2 {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 10rem;
          width: 10rem;
          border-radius: 10rem;
          background-color: #8860e6b0;
        }
      }
    }
    .hidden {
      display: none;
    }
    color: #fff;
    .designation {
      height: 8rem;
    }
  }
  .controls{
    display: flex;
    gap: 1rem;
    button {
      padding: 0.5rem;
      border-radius: 1rem;
      background-color: #fff;
      border: 0.1rem solid transparent;
      cursor: pointer;
    }
    .active {
      background-color: transparent;
      border: 0.1rem solid #fff;
    }
  }
}
@media screen and (min-width: 280px) and (max-width: 1080px) { 
  .background {
    display: none;
  }
  .container {
    padding: 4rem 0;
    .testimonials {
      width: 80%;
      .testimonial {
        .description {
          height: 18rem;
        }
      }
    }
  }
}
`;

export default Testimonials