import Navbar from "components/Navbar";
import React from "react";
import logo from "../assets/logo2.png";
import hero from "../assets/Electromechanical/hero.jpg";
import colling from "../assets/Electromechanical/1.jpg";
import Generator from "../assets/Electromechanical/2.jpg";
import Footer from "components/Footer";
import Pump from "../assets/Electromechanical/hero1.jpg";
function Electromechanical() {
  return (
    <div className="text-primary text-justify">
      <Navbar color="text-primary" logo={logo} page="services" />
      <div className="flex flex-col items-center">
        <h1 className="font-primary text-4xl lg:text-5xl text-primary">
          Electromechanical Works
        </h1>
      </div>
      <div className="w-full  pt-10 flex flex-col justify-center items-center">
        <img
          src={hero}
          alt=""
          className="w-full object-contain lg:w-[80%] h-[20rem] lg:object-cover"
        />
      </div>
      <div className="p-10">
        <h2 className="text-primary font-primary text-2xl">
          Power System Control, Quality Improvement and Distribution works
        </h2>
        <p className="text-xl p-2">
          We supply and install :
          <ul className="mx-10 mt-2">
            <li>
              <span className="text-xl font-extrabold">·</span> Compact
              substation with MV ATS
            </li>
            <li>
              <span className="text-xl font-extrabold">·</span> Low Voltage
              Switchgear control panel
            </li>
            <li>
              <span className="text-xl font-extrabold">·</span> Automatic
              Voltage regulators and Harmonic Filters
            </li>
            <li>
              <span className="text-xl font-extrabold">·</span> Phase Sequence
              Correctors
            </li>
            <li>
              <span className="text-xl font-extrabold">·</span> Multiple input
              ATS switches for mission critical service (telecom core sites and
              data centers)
            </li>
            <li>
              <span className="text-xl font-extrabold">·</span> Rectifies
              (telecom sites)
            </li>
            <li>
              <span className="text-xl font-extrabold">·</span> Irrigation pumps
              with pump controllers
            </li>
          </ul>
        </p>
      </div>

      <div>
        <h2 className="font-primary text-primary text-2xl font-semibold pl-10 py-4">
          1. Heat Ventilation and Air-conditioning (HVAC)
        </h2>
        <div className="lg:flex lg:w-full lg:items-center ">
          <img
            src={colling}
            alt=""
            className="lg:h-[25rem] lg:w-[50rem] lg:object-cover object-contain lg:px-10"
          />
          <p className="lg:w-[50rem] lg:px-10 mx-10 text-lg pt-9 lg:pt-0">
            <ul>
              <li>
                <span className="text-xl font-extrabold">·</span> Variable
                Refrigerant Flow(VRF) controlled HVAC for hotels, offices and
                meeting halls
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Precision AC
                for data centers
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Fresh air
                supplies
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Hot-air
                exhaust for kitchens and factories
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Air duct
                installation
              </li>
            </ul>
          </p>
        </div>
      </div>

      <div className="mt-7 mb-5">
        <h2 className="font-primary text-primary text-2xl font-semibold pl-10 py-4">
          2. Diesel Generator
        </h2>
        <div className="lg:flex lg:w-full lg:items-center lg:flex-row-reverse ">
          <img
            src={Generator}
            alt=""
            className="lg:h-[25rem] lg:w-[50rem] lg:object-cover object-contain lg:px-10"
          />
          <p className="lg:w-[50rem] lg:px-10 mx-10 text-lg lg:pt-0"> 
           <strong> 
            We supply and install DGs of :
            </strong>
            <br />
            <ul>
            <li>
              <span className="text-xl font-extrabold">·</span> Sound attenuated capacity of 25kVA to 2000KA
            </li>
            <li>
              <span className="text-xl font-extrabold">·</span> Standby and continuous duty
            </li>
            <li>
              <span className="text-xl font-extrabold">·</span> Embedded and separate fuel tanks
            </li>
            <li>
              <span className="text-xl font-extrabold">·</span> Embedded and separate ATS controllers
            </li>
            <li>
              <span className="text-xl font-extrabold">·</span> Remote fuel level monitoring
            </li>
            <li>
              <span className="text-xl font-extrabold">·</span> Remote DG alarms monitoring
            </li>
            <li>
              <span className="text-xl font-extrabold">·</span> Power cabling and termination
            </li>
            <li>
              <span className="text-xl font-extrabold">·</span> Maintenance service
            </li> 
            </ul>
          </p>
        </div>
      </div>

      <div>
        <h2 className="font-primary text-primary text-2xl font-semibold pl-10 py-4 pb-4">
          3. Surface Pumps and Submerssible Irrigation Pumps
        </h2>
        <div className="lg:flex lg:w-full lg:items-center ">
          <img
            src={Pump}
            alt=""
            className="lg:h-[25rem] lg:w-[50rem] lg:object-cover object-contain lg:px-10"
          />
          <p className="lg:w-[50rem] lg:px-10 mx-10 text-lg pt-9 lg:pt-0">
          We design, supply and Install pumps used for Irrigation works
          We design with appropriate selections of:
            <ul>
              <li>
                <span className="text-xl font-extrabold">·</span> flow rate
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> adequate pressure
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Discharge Head and Suction Head
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> TDH
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Compatible with water quality
              </li>
            </ul>
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Electromechanical;
