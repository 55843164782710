import React from 'react';
import styled from "styled-components";
import loadmore from "assets/loadmore.png";

import { motion } from "framer-motion";
import { useScroll } from "./useScroll";
import { portfolioAnimations } from "animation";
import  Loading from './Loading'

import { useQuery, gql } from "@apollo/client"
import { Link } from 'react-router-dom';


const GALLERY_QUERY = gql`
    {
galleries(first:6){
  edges{
    node{
      galleryMeta{
        image{
          sourceUrl
        }
      }
    }
  }
}
}
  `
function Gallery() {
  const { data, loading, error } = useQuery(GALLERY_QUERY);
  const [element, controls] = useScroll();
  console.log(data)
  if (loading) return <Loading />;
  if (error) return <pre>{error.message}</pre>

  const dataa = data.galleries.edges

  return (
  <Section id="portfolio" ref={element} className='w-screen'>
    <div className=" lg:grid-cols-3 w-full bg-secondary lg:space-y-0 lg:grid  lg:grid-rows-3">
    {(() => {
        const rows = [];
        for (let i = 0; i < 6; i++) {
          rows.push(
            i===1?(
              <motion.div className="w-full col-span-2 row-span-2 rounded" variants={portfolioAnimations} animate={controls} transition={{delay:0.03, type: "tween", duration: 0.8}}>
              <img src={dataa[i].node.galleryMeta.image.sourceUrl} alt="image" className='h-full w-full object-cover'/>
           </motion.div>
            ):(
              <motion.div className="w-full rounded" variants={portfolioAnimations} animate={controls} transition={{delay:0.03, type: "tween", duration: 0.8}} >
              <img src={dataa[i].node.galleryMeta.image.sourceUrl} alt="image" className='h-full w-full w-full object-cover'/>
          </motion.div>
            )
          );
        }
        return rows;
      })()}
    </div>

    <div className="portfolio-more font-primary">
        <span>Load More</span>
        <Link to="/gallery">
          <img src={loadmore} alt="Load More" />
        </Link>
    </div> 
    
  </Section>
  );
}

const Section = styled.section`
min-height: 100vh;
padding-bottom: 2rem;
background-color: var(--secondary-color);

.portfolio-more {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 4rem 0;
  span {
    color: #fff;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }
}
/* @media screen and (min-width: 280px) and (max-width: 1080px) { 
  .container{
    .photo
  }
} */
`;

export default Gallery