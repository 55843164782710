import React from 'react';
import Slider from "react-slick";
import { motion } from "framer-motion";
import { useScroll } from "./useScroll";
import { pricingAnimation } from "animation";
import { useQuery, gql } from "@apollo/client";
import  Loading from './Loading'


const CLIENTS_QUERY = gql`
{
clients(first:100){
  edges{
    node{
      clientMeta{
        logo{
          sourceUrl
        }
      }
    }
  }
}
}
` 


function Clients() {
  const [element, controls] = useScroll();
  const { data, loading, error } = useQuery(CLIENTS_QUERY);
  if (loading) return <Loading />;
  if (error) return <pre>{error.message}</pre>
  const clientsList = data.clients.edges
  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    // initialSlide: 0,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          autoplaySpeed: 100,
          speed: 5000,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          autoplaySpeed: 200,
          speed: 5000,

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplaySpeed: 400,
        }
      }
    ]
  };
  return (
    <div ref={element} className = ''>    
      <div className="">
  
            <motion.div className="pricing__plan" 
            variants={pricingAnimation}
            animate={controls}
            transition={{
              delay: 0.03,
              type: "tween",
              duration: 0.8,
            }}
            >
                <h2 className='text-5xl text-primary py-4 pl-7 font-primary'>Our Clients</h2>
               <div className="items-center overflow-x-hidden">
      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
      />
      <Slider {...settings} className = 'flex items-center pt-10 '>
        
      {clientsList.map((client)=>(
        <div>
          <img src={client.node.clientMeta.logo.sourceUrl} className="w-36" alt=""/>
        </div>

      ))}

      </Slider>
    </div>

            </motion.div>
         
      </div>
    </div>
  )
}

export default Clients