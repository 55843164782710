import React from 'react'
import HomePage from 'pages/HomePage'
import AboutusPage from 'pages/AboutusPage'
import GalleryPage from 'pages/GalleryPage'
import ServicesPage from 'pages/ServicesPage'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes
} from "react-router-dom";
import ContactPage from 'pages/ContactPage'
import ICTandDataCom from 'pages/ICTandDataCom'
import PowerandEnergy from 'pages/PowerandEnergy'
import Electromechanical from 'pages/Electromechanical'
import Sales from 'pages/Sales'
function App() {
  return (
    <Router>
      <div>
      {/* <AboutusPage /> */}
      {/* <HomePage /> */}
      {/* <GalleryPage /> */}
      <Routes>
          <Route path="/">
            <Route index element={<HomePage />} />
            <Route path='about' element={<AboutusPage />} />
            <Route path='gallery' element={<GalleryPage />} />
            <Route path='services' element={<ServicesPage />} />
            <Route path='contact' element={<ContactPage />} />
            <Route path='services/ict' element={<ICTandDataCom />} />
            <Route path='services/power' element={<PowerandEnergy />} />
            <Route path='services/electromechanical' element={<Electromechanical/>} />
            <Route path='services/sales' element={<Sales/>} />
          </Route>
      </Routes>
      </div>
    </Router>
  )
}

export default App