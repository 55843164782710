import React from 'react'
import SkewLoader from 'react-spinners/SkewLoader'
import styled from 'styled-components'

function Loading() {
  return (
    <Container>
        <SkewLoader color='#004883' className='w-[10rem] h-[10rem]'/>
    </Container>
  )
}

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    position: absolute;
    top:0;
    background-color: #fff;
`

export default Loading