import React from 'react'
import { useQuery, gql } from "@apollo/client";
import Loading from './Loading';


const POWERSALES = gql`
{
  powerSales {
    edges {
      node {
        powerSalesMeta{
          title,
          image{
            mediaItemUrl
          }
        }
      }
    }
  }
}
`

function PowerSales() {
  const { data, loading, error } = useQuery(POWERSALES);
  if (loading) return <Loading />;
  if (error) return <pre>{error.message}</pre>
  return (
    <div className='my-10'>
        <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-2 space-y-7 md:space-y-0'>
        {
            data.powerSales.edges.map((power)=>(
                    <div className='flex flex-col md:flex-row items-center space-y-7 md:space-y-0 md:space-x-5'>
                        <img src ={power.node.powerSalesMeta.image.mediaItemUrl} className= 'w-60 h-40 object-cover' />
                        <p className='text-xl text-primary capitalize'>{power.node.powerSalesMeta.title}</p>
                    </div>
            ))
        }
        </div>
    </div>
  )
}

export default PowerSales