import React from 'react'
import milestoneBackground from "assets/milestone-background.png";
import styled from "styled-components";
import { useScroll } from "./useScroll";
import { motion } from "framer-motion";
import { milestonesAnimations } from "animation";



function Milestones() {
  const [element, controls] = useScroll();

  
  return <Section ref={element} className= 'flex justify-center items-center'>
    <div className="background">
        <img src={milestoneBackground} alt="Milestone Background" />
    </div>
    <div className="milestones text-white ">
            <motion.div className="milestone flex flex-col justify-center items-center space-y-20"
            variants={milestonesAnimations}
            animate={controls}
            transition={{
              delay: 0.03,
              type: "tween",
              duration: 0.8,
            }}
            >
              <h2 className='text-5xl font-bold font-primary'>WHY US</h2>
              <div className='lg:grid lg:grid-cols-3 lg:space-x-7 space-y-10 lg:space-y-0 '>
                <div className='space-y-5 border-t-8 border-primary py-3 px-2'>
                  <h3 className='text-2xl font-bold font-primary'>WE ARE TRUSTED</h3>
                  <p className='font-secondary capitalize'>We go extra miles to add value for our customers by offering high-quality products and solutions at competitive prices.</p>
                </div>
                <div className='space-y-5 border-t-8 border-primary py-3 px-2'>
                  <h3 className='text-2xl font-bold font-primary'>WE ARE PROFESSIONAL</h3>
                  <p className='font-secondary capitalize'>founded by professionals in electrical and systems engineering who had aspirations for greatness in both technology and service to Ethiopian society. </p>
                </div>
                <div className='space-y-5 border-t-8 border-primary py-3 px-2'>
                  <h3 className='text-2xl font-bold font-primary'>WE ARE EXPERT</h3>
                  <p className='font-secondary capitalize'>The founders of the company have a long journey of experiences in the areas of Power Generation, Energy demand Management, Power System, ICT and Telecom Sectors as designer, operation management and Service Integrator.</p>
                </div>
              </div>
            </motion.div>
          
      
    </div>
  </Section>
}

const Section = styled.section`
height: 100vh;
background-color: var(--primary-color);
padding: 0 10rem;
position: relative;
.background {
  position: absolute;
  left: 0;
  bottom: -30%;
  img {
    z-index: 2;
    height: 43rem;
  }
}

@media screen and (min-width: 280px) and (max-width: 1080px) { 
  padding: 5rem 2rem;
  min-height: 100ch;
  height: 100%;
  .background {
    display: none;
  }
  .milestones {
    grid-template-columns: 1fr;
    gap: 5rem;
  }
}
`;

export default Milestones;