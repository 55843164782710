import Navbar from 'components/Navbar'
import React from 'react'
import logo from '../assets/logo2.png'
import Footer from 'components/Footer'
import ITSales from 'components/ITSales'
import PowerSales from 'components/PowerSales'

function Sales() {
  return (
    <div className='text-primary text-justify'>
        <Navbar color="text-primary" logo={logo} page="services"/>
        <div className='flex flex-col items-center'>
            <h1 className='font-primary text-4xl lg:text-5xl text-primary'>Industrial and Sales Services</h1>
        </div>
        <div className='p-10'>
            <h2 className='text-2xl font-primary text-primary '>Industrial Services- consulting, designing, and supplying:</h2>
            <ul className='pl-4'>
                <li className='flex items-center'>
                    <span className='text-5xl'>&middot;</span>Industrial Automation (PLC based controllers and DCS building)
                </li>
                <li className='flex items-center'>
                <span className='text-5xl'>&middot;</span>Building Management Systems (BMS) deployment
                </li>
            </ul>
        </div>

        <div className='p-10'>
            <h2 className='text-2xl font-primary text-primary '>Sales outlets:</h2>
            <div className='pt-7'>
                <h2 className='text-xl font-primary text-primary text-center'>Sales of IT equipment and services</h2>
                <div>
                    <ITSales />
                </div>
                <h2 className='text-xl font-primary text-primary text-center'>Sales of Power equipment</h2>
                <div>
                    <PowerSales />
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Sales