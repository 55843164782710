import React from "react";
import styled from "styled-components";
import Navbar from "./Navbar";
import { motion } from "framer-motion";
import { homeAnimation, homeInfoAnimation } from "animation";
import Loading from "./Loading";
import Nav from "./Nav";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";

import Hero from '../assets/hero1.png'

const HERO_QUERY = gql`
  {
    heros {
      edges {
        node {
          heroMeta {
            description
            title
            image {
              sourceUrl
            }
            logo {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;

function Home() {
  const { data, loading, error } = useQuery(HERO_QUERY);

  if (loading) return <Loading />;
  if (error) return <pre>{error.message}</pre>;

  const dataa = data.heros.edges[0].node.heroMeta;
  const lastWord = dataa.title.split(" ").pop();
  const style = {
    // backgroundImage: `url(${dataa.image.sourceUrl})`,
  };
  return (
    <Section id="home" style={style} className="">
      <Navbar logo={dataa.logo.sourceUrl} color="text-primary" page="home" />
      {/* <Nav name={"home"}/> */}
      <motion.div
        className="home flex items-center justify-center md:flex-row-reverse flex-col justify-around"
        variants={homeAnimation}
        transition={{ delay: 0.3, duration: 0.6, type: "tween" }}
      >
        <div className="px-10"> 
          <img src={Hero} alt="" className=" w-[20rem] md:w-[30rem] lg:w-[60rem]"/>
        </div>

        <div className="content py-2 px-10 lg:w-[80%]">
          <div className="">
            <div className="subTitle">
              <p className="font-secondary text-primary text-justify">
              Vascom is a company highly experienced in Power System solution, Telecom and Electro-mechanical works striving to cater for indigenous customer demands in the fields of Data center, WAN and LAN – infrastructure, Power and Energy solutions, Electro-mechanical Projects supply and installation.
              </p>
            </div>
            <div className="title text-primary">
            Last mile Goal,
              <h1
                className="text-[1.5rem] font-bold font-primary md:text-[2rem] lg:text-[3.7rem] lg:font-semibold"
                data-text={lastWord}
              >
                {dataa.title}
              </h1>
            </div>
          </div>
        </div>
        
      </motion.div>
    </Section>
  );
}

const Section = styled.section`
  min-height: 100vh;
  background-size: cover;
  position: relative;
  .home {
    height: 100%;
    .content {
      margin-top: 2rem;
      .title {
        h1:after {
          content: attr(data-text);
          color: var(--primary-color);
          transform: translateX(-100%);
          position: absolute;
        }
      }
      .subTitle {
        p {
          margin-bottom: 2rem;
        }
      }
    }
  }
  .info {
    position: absolute;
    bottom: -6rem;
    right: 0;
    background-color: var(--primary-color);
    padding: 3rem;
    .grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 4rem;
      color: #fff;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    .home {
      .content {
        padding-left: 2rem;
        width: 100%;
        margin-bottom: 2rem;
        /* .title {
          h1 {
            font-size: 2rem;
            line-height: 2rem;
          }
        } */
      }
    }
    .info {
      position: initial;
      .grid {
        grid-template-columns: 1fr;
      }
    }
  }
`;

export default Home;
