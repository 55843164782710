import React, {useState} from 'react';
import styled from "styled-components";
import { FaChevronUp } from 'react-icons/fa';
import Tg from '../assets/icon/tg.png'
import whatsapp from '../assets/icon/598px-WhatsApp_icon.png'

function Chat() {
  const [visible, setVisible] = useState(false);
  window.addEventListener("scroll", ()=> {
    // setVisible(true)
    window.pageYOffset > 100 ? setVisible(true) : setVisible(false);
  })
  return (
    <div className={`fixed bottom-[120px] right-[30px] flex flex-col justify-center items-center `}>
        {/* Telegeram*/}
        <div className={`w-20 hover:shadow-md rounded-full duration-200 transition-[0.4s] ease-in-out cursor-pointer ${visible ? "block" : "opacity-0 hidden"}`}>
            <a href="https://t.me/Ethvas">
            <img src={Tg} alt="" className=''/>
            </a>
            {/* <div className='bg-green-400 h-5 w-5 rounded-full top-2 left-12 absolute'/> */}
        </div>
        {/* Whatsapp */}
        <div className={`w-20 hover:shadow-md rounded-full transition-[0.4s] ease-in-out cursor-pointer ${visible ? "block" : "opacity-0 hidden"} duration-200`}>
            <a href="https://wa.me/+251911211391">
            <img src={whatsapp} alt="" />
            </a>
            {/* <div className='bg-green-400 h-5 w-5 rounded-full top-2 left-12 absolute'/> */}
        </div>
        {/* <div className='bg-[#2683c1] p-3 relative rounded-lg cursor-pointer hover:shadow-md active:scale-95 transition-transform ease-in-out'>
            <p1 className = "text-white font-bold">Chat on Telegram</p1>
            
        </div> */}
    </div>
    // <Div>
    //   <a href="#" className={`${visible ? "block" : "none"}`}>
    //     <FaChevronUp />
    //   </a>
    // </Div>
  )
}

const Div = styled.div`
max-width: 100vw;
.none {
  opacity: 0;
  visibility: hidden;
}
a {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: var(--secondary-color);
  padding: 1rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: 0.4s ease-in-out;
  svg {
    color: #fff;
    font-size: 1.3rem
  }
}
`;

export default Chat