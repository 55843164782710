import Navbar from 'components/Navbar'
import React from 'react'
import logo from '../assets/logo2.png'
import hero from '../assets/ict/1.jpg'
import DataCenter from '../assets/ict/2.png'
import colling from '../assets/ict/3.png'
import UPS from '../assets/ict/ups.png'
import APS from '../assets/ict/5.jpg'
import ATS from '../assets/ict/6.png'
import Fire from '../assets/ict/7.png'
import Scurity from '../assets/ict/8.png'
import Gate from '../assets/ict/9.jpg' 
import DoorAccess from '../assets/ict/10.PNG' 
import UVSS from '../assets/ict/11.jpg'
import CCTV from '../assets/ict/cctv.png'
import Footer from 'components/Footer'
function ICTandDataCom() {
  return (
    <div className=''>
        <Navbar color="text-primary" logo={logo} page="services"/>
        <div className='flex flex-col items-center'>
            <h1 className='font-primary text-4xl lg:text-5xl text-primary'>ICT and Data-Com</h1>
        </div>
        <div className='w-full  pt-10'>
            <img src={hero} alt="" className='w-screen h-[20rem] object-cover' />
        </div>
        <div className='p-10 !text-primary'>
            <p className='text-xl p-2'>
            IT and Data-Com is one of the core business area we are proficiently involved in. Among the scopes we cover include:
            </p>
            <ul className='pl-10 space-y-6'>
                <li className='text-lg'>
                Turnkey projects on building data centers (Traditional as well HCI)
                </li>
                <li className='text-lg'>
                Errection of telecom sites such as BTS, MSAG, EPON, FTH infrastructure
                </li>
                <li className='text-lg'>
                Long and medium range wireless network connectivity
                </li>
                <li className='text-lg'>
                Unified communications (IP-telephony, IP-PABX) and call center
                </li>
                <li className='text-lg'>
                Video Conferencing and smart class solutions with e-Learning platforms
                </li>
                <li className='text-lg'>
                Deployment of Local Area and Wide Area Network Infrastructure (Traditional as well SDLAN and SDWAN)
                </li>
                <li className='text-lg'>
                IT Infrastructure design to keeping customer needs to the Cloud and Hybrid data centers
                </li>
                <li className='text-lg'>
                Building IT service platforms to support customer needs to align them with the digital transformation dynamics
                </li>
                <li className='text-lg'>
                Build and administer managed services
                </li>
            </ul>
        </div>
        <div className='xl:px-32 xl:py-10 text-primary text-justify'>
        <div>
            <h2 className='font-primary text-primary text-2xl font-semibold pl-10 py-4'>1. Data Center Infrastructure</h2>
            <div className='lg:flex lg:w-full lg:items-center '>
                <img src={DataCenter} alt="" className='lg:h-[25rem] lg:w-[50rem] lg:object-cover object-contain lg:px-10'/>
                <p className='lg:w-[50rem] lg:px-10 mx-10 text-lg pt-9 lg:pt-0 text-justify '>
                We design, supply and install full-fledged data center packages including the hardware devices(servers, firewalls, coreswitches, distribution switches, core routers, edge routers),Power devices(AVRs,Gensets, UPSs, batteries, PDUs,ATS PDUs), Data center environment monitoring(Fire &smoke detection with suppression, flood detection, water leak detection), HVAC(cooling,heating,airflow) , Access control to DC(door access, vibration sensing),overall data center health local and remote monitoring using standard DCIM solutions <br/> <br/>
                Constructing the data center with fire retardant floor materials, ceiling tile and wall cladding are parts of our turnkey projects.<br/> <br/>

                Designs start with business continuity plan review to support clients with the data center availability plan as Tier-1,2,3 or 4. We support our client’s data center audit service complying to renowned international standard procedures to meet and be certified by Uptime, TIA or equivalent bench marks.

                </p>
            </div>
        </div>
        
        <div className='mt-7'>
            <h2 className='font-primary text-primary text-2xl font-semibold pl-10 py-4'>2. Data Center HVAC</h2>
            <div className='lg:flex lg:w-full lg:items-center lg:flex-row-reverse '>
                <img src={colling} alt="" className='lg:h-[25rem] lg:w-[50rem] lg:object-cover object-contain lg:px-10'/>
                <p className='lg:w-[50rem] lg:px-10 mx-10 text-lg pt-9 lg:pt-0'>
                Modular In-Rack and In-Row Smart Cooling are state of art data center smart cooling solution with highest achievable energy efficiency for data center HVAC needs. Design is for cold aisle or hot aisle containment with up/downflow provision.
                The in-row and in-rack precis   ion units are provided with redundant skid mounted condenser units.

                </p>
            </div>
        </div>

        <div className='mt-7'>
            <h2 className='font-primary text-primary text-2xl font-semibold pl-10 py-4'>3. Modular UPSs and Smart PDUs</h2>
            <div className='lg:flex lg:w-full lg:items-center '>
                <img src={UPS} alt="" className='lg:h-[30rem] lg:w-[50rem] object-contain lg:px-10'/>
                <p className='lg:w-[50rem] lg:px-10 mx-10 text-lg pt-9 lg:pt-0'>
                The modular UPS design for growing data centers are most economical leveraging power
                backup time and power output scalability which is the modern trend of investment in IT.<br/>
                The UPSs we use for data center have the following minimum features:<br/>
                <div className='pl-4'>
                · Modular design<br/>
                · Hot swappable batteries and power modules<br/>
                · Intelligent controller with remote management interface<br/>
                · Durable batteries of 100% GEL Electrolyte<br/>
                · Energy efficient and Transformer less design<br/>
                · Compensated PF to 1.0<br/>
                · In-rack design<br/>
                · Ease of maintenance<br/>
                </div>

                </p>
            </div>
        </div>

        <div className=''>
            <h2 className='font-primary text-primary text-2xl font-semibold pl-10 py-4'>4. Rack-Mount UPSs</h2>
            <div className='lg:flex lg:w-full lg:items-center lg:flex-row-reverse '>
                <img src={APS} alt="" className='lg:h-[25rem] lg:w-[50rem] object-contain lg:px-10'/>
                <p className='lg:w-[50rem] lg:px-10 mx-10 text-lg pt-9 lg:pt-0'>
                Rack mount UPSs of 3kVA to 10kVA are our ranges of supply and installation for mini-data center needs.
                </p>
            </div>
        </div>
        <div>
            <h2 className='font-primary text-primary text-2xl font-semibold pl-10 py-4'>5. Rack Mount ATS Smart PDUS(N+1)</h2>
            <div className='lg:flex lg:w-full lg:items-center '>
                <img src={ATS} alt="" className='lg:h-[25rem] lg:w-[40rem]  object-contain lg:px-10 '/>
                <p className='lg:w-[50rem] lg:px-10 mx-10 text-lg pt-9 lg:pt-0'>
                Smart Metered ATS PDUs are provide with full rack height provided with dual-power line source. In the event of power failure from one the sources, the second line will parallelly takeover the data center loads
                </p>
            </div>
        </div>

        <div className=''>
            <h2 className='font-primary text-primary text-2xl font-semibold pl-10 py-4'>6. Fire Suppression System</h2>
            <div className='lg:flex lg:w-full lg:items-center lg:flex-row-reverse '>
                <img src={Fire} alt="" className='lg:h-[25rem] lg:w-[50rem] object-contain lg:px-10'/>
                <p className='lg:w-[50rem] lg:px-10 mx-10 text-lg pt-9 lg:pt-0'>
                Fire catastrophe is one of the prominent business disrupting incidents in data centers, power houses, factories, warehouses, and shopping malls.<br/>
                We design, supply, and fit the latest technology trends in the sector using FM200 or NOVEC clean agent gases actuated by conventional and addressable intelligent for controllers as well incident sensors (smoke, fire, heat, CO2 and other gas compositions using VESDA sensors)

                </p>
            </div>
        </div>

        <div className=''>
            <h2 className='font-primary text-primary text-2xl font-semibold pl-10 py-4'>7. Access Control and Time Attendance System </h2>
            <div className='lg:flex lg:w-full lg:items-center'>
                <img src={Scurity} alt="" className='lg:h-[20rem] lg:w-[50rem] object-contain lg:px-10'/>
                <p className='lg:w-[50rem] lg:px-10 mx-10 text-lg pt-9 lg:pt-0'>
                We design, supply, and deploy multifactor biometric time attendance system that play important role in controlling productive working hours of employees to evaluate efficiency and performance.<br/><br/> 

                Time attendance integration with payroll and leave management systems.    
                Application Sector: Factories, Universities, Schools, Hospitals, Enterprises etc.

                </p>
            </div>
        </div>

        <div className=''>
            <h2 className='font-primary text-primary text-2xl font-semibold pl-10 py-4'>8. Turnstile Gate Control</h2>
            <div className='lg:flex lg:w-full lg:items-center lg:flex-row-reverse '>
                <img src={Gate} alt="" className='lg:h-[25rem] lg:w-[50rem] object-contain lg:object-cover lg:px-10'/>
                <p className='lg:w-[50rem] lg:px-10 mx-10 text-lg pt-9 lg:pt-0'>
                We design and supply and install turnstile queue and entry management systems. Face recognition type turnstiles have come into service after covid-19, and we install this for contactless demands.
                </p>
            </div>
        </div>

        <div className=''>
            <h2 className='font-primary text-primary text-2xl font-semibold pl-10 py-4'>9. Door Access Control </h2>
            <div className='lg:flex lg:w-full lg:items-center'>
                <img src={DoorAccess} alt="" className='lg:h-[20rem] lg:w-[50rem] object-contain lg:object-cover lg:px-10'/>
                <p className='lg:w-[50rem] lg:px-10 mx-10 text-lg pt-9 lg:pt-0'>
                Single to multiple doors access control with centralized logic controller and access list data base. The Access Control system identification can be biometric, eyes can, finger, magnetic cards or combination of these.

                </p>
            </div>
        </div>

        <div className=''>
            <h2 className='font-primary text-primary text-2xl font-semibold pl-10 py-4'>10. UVSS and Gate Barrier Systems</h2>
            <div className='lg:flex lg:w-full lg:items-center lg:flex-row-reverse '>
                <img src={UVSS} alt="" className='lg:h-[25rem] lg:w-[42rem] object-contain lg:px-10'/>
                <p className='lg:w-[50rem] lg:px-10 mx-10 text-lg pt-9 lg:pt-0'>
                We design, supply, and install under vehicle surveillance and gate barrier system for buildings, office compounds, parking lots and bus stations.
                </p>
            </div>
        </div>

        <div className=''>
            <h2 className='font-primary text-primary text-2xl font-semibold pl-10 py-4'>11. CCTV (IP-Survilliance) System</h2>
            <div className='lg:flex lg:w-full lg:items-center'>
                <img src={CCTV} alt="" className='lg:h-[20rem] lg:w-[50rem] object-contain lg:px-10'/>
                <p className='lg:w-[50rem] lg:px-10 mx-10 text-lg pt-9 lg:pt-0'>
                We design, supply, and install video surveillance cameras for security control of compound, perimeter fence, factory production lane, express ways, residence area, warehouse stocks, compound gates, hydropower plants, substations, telecom sites, datacenters, and military posts.<br/>
                Our surveillance cameras latest state of art features of video analytics, artificial intelligence and deep learning algorithm software including cloud-based video management.<br />
                IP based NVR and Video Storages are the heart of the CCTV system.

                </p>
            </div>
        </div>
        </div>
        <Footer />
    </div>
  )
}

export default ICTandDataCom