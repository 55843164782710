import React from "react";
import styled from "styled-components";
import Title from "./Title";
import { useScroll } from "components/useScroll";
import { motion } from "framer-motion";
import { servicesAnimations } from "animation";
import Loading from "./Loading";
import { Link } from "react-router-dom";
import Ict from "../assets/services/ict.jpg";
import Power from "../assets/services/power.jpg";
import Electromechanical from "../assets/services/electro.jpg";
import Industry from "../assets/services/indust.png"

import { useQuery, gql } from "@apollo/client";

const Serivece_QUERY = gql`
  {
    homeServices {
      edges {
        node {
          homeServiceMeta {
            title
            description
            image {
              sourceUrl
            }
            color
            link
          }
        }
      }
    }
  }
`;

function Services() {
  const { data, loading, error } = useQuery(Serivece_QUERY);
  const [element, controls] = useScroll();
  // console.log(data)

  if (loading) return <Loading />;
  if (error) return <pre>{error.message}</pre>;

  const dataas = data.homeServices.edges;

  return (
    <Section
      id="services"
      ref={element}
      className="overflow-y-hidden  flex flex-col w-screen"
    >
      <Title value="services" />
      <div className="services grid grid-cols-2">
        {/* ICT */}
        <motion.div
          className="services__service w-[100%] "
          variants={servicesAnimations}
          animate={controls}
          transition={{
            delay: 0.03,
            type: "tween",
            duration: 0.8,
          }}
        >
          <div className="services__service__image w-[100%]">
            <img
              src={Ict}
              alt="Service "
              className="w-[300px] h-[200px] object-cover"
            />
          </div>
          <div className="services__service__title">
            <span>01</span>
            <h2 className={`text-primary font-primary`}>ICT Solutions</h2>
          </div>
          <p className="services__service__description font-secondary text-justify pt-3">
            IT services are fastidiously growing to complement the digital
            transformation strategy of the country. We as an IT Service
            integrator are working to provide services including :
            <ul className="pl-5">
              <li>
                <span className="text-xl font-extrabold">·</span> Build and
                operate full-fledged data centers
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Supply and
                install computing, core to edge network hardware
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Implement
                wireless and cable networks
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Implement
                Network security appliances
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Install
                surveillance and CCTV systems etc….
              </li>
            </ul>
          </p>
        </motion.div>

        {/* POWER */}
        <motion.div
          className="services__service"
          variants={servicesAnimations}
          animate={controls}
          transition={{
            delay: 0.03,
            type: "tween",
            duration: 0.8,
          }}
        >
          <div className="services__service__image ">
            <img
              src={Power}
              alt="Service "
              className="w-[300px] h-[200px] object-cover"
            />
          </div>
          <div className="services__service__title">
            <span>02</span>
            <h2 className={`text-primary font-primary`}>
              Power and Energy Solutions
            </h2>
          </div>
          <p className="services__service__description font-secondary text-justify pt-3">
            Power and Energy is the key to every other infrastructure and
            services. We design, supply and install :
            <ul className="pl-5">
              <li>
                <span className="text-xl font-extrabold">·</span> Diesel standby
                power
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Solar hybrid
                power
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Lithium
                battery banks
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> AGM battery
                banks
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Smart hybrid
                power inverters
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Rectifiers
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Load balancers
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Phase Sequence
                Correctors
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Automatic
                Voltage Regulators
              </li>
            </ul>
          </p>
        </motion.div>

        {/* Electro */}

        <motion.div
          className="services__service"
          variants={servicesAnimations}
          animate={controls}
          transition={{
            delay: 0.03,
            type: "tween",
            duration: 0.8,
          }}
        >
          <div className="services__service__image ">
            <img
              src={Electromechanical}
              alt="Service "
              className="w-[300px] h-[200px] object-cover"
            />
          </div>
          <div className="services__service__title">
            <span>03</span>
            <h2 className={`text-primary font-primary`}>
              Electromechanical Works
            </h2>
          </div>
          <p className="services__service__description font-secondary text-justify pt-3">
            One of the most prevalent projects we are involved in include
            design, supply and installation of Electro-mechanical Equipment such
            as :
            <ul className="pl-5">
              <li>
                <span className="text-xl font-extrabold">·</span>Compact substation transformers
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Irrigation water pumps
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Piping system
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Voltage regulators
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Frequency drives
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Mechanical supplies
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span>Air Conditioning systems
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Factory machines
              </li>
            </ul>
            We pay deeper attentions to electrical power system infrastructure projects that sooth problematic client utilities.
          </p>
        </motion.div>

        {/* Industrial */}
        <motion.div
          className="services__service"
          variants={servicesAnimations}
          animate={controls}
          transition={{
            delay: 0.03,
            type: "tween",
            duration: 0.8,
          }}
        >
          <div className="services__service__image ">
            <img
              src={Industry}
              alt="Service "
              className="w-[300px] h-[200px] object-cover"
            />
          </div>
          <div className="services__service__title">
            <span>04</span>
            <h2 className={`text-primary font-primary`}>
            Industrial Services and Trading
            </h2>
          </div>
          <p className="services__service__description font-secondary text-justify pt-3">
          This business unit mainly focus on industry solutions using local skills to design and build products and automation such as :
            <ul className="pl-5">

              <li>
                <span className="text-xl font-extrabold">·</span>Industrial process automation
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Power flow and ATS controllers
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Water flow control boards
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> LV and MV switchgear panels
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Soft starts and Start-delta starts
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Fluid level sensors and regulators
              </li>
            </ul>
            We use our own workshop to build the above and installation, test and commissioning with aftersales support. The sales outlets cater electrical consumables for clients, with technical advisors. Sales of :
            <ul className="pl-5">
              <li>
                <span className="text-xl font-extrabold">·</span> Circuit breakers and Contactors
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Relays and Controllers
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Solar lanterns and Generators
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Inverters and Batteries
              </li>
              <li>
                <span className="text-xl font-extrabold">·</span> Solar panels and ICT materials
              </li>
            </ul>
          </p>
        </motion.div>
      </div>
      <div className="w-screen flex flex-col items-center p-10">
        <Link to="/services">
          <div className="border border-primary text-primary text-3xl p-3 font-primary hover:bg-primary hover:text-white transform transition-all duration-150 ease-in-out w-[10rem]">
            See More
          </div>
        </Link>
      </div>
    </Section>
  );
}

const Section = styled.section`
  min-height: 100vh;
  .services {
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    // grid-template-columns: repeat(10, 1fr);
    /* align-items: center; */
    // height: 100%;
    margin: 0 14rem;
    margin-top: 10rem;
    // gap: 5rem;
    &__service {
      padding: 3rem;

      &__image {
        margin-bottom: 2rem;
        object-fit: cover;
      }
      &__title {
        span {
          color: var(--primary-color);
          font-weight: bolder;
        }
        h2 {
          width: 300px;
          height: 100px;
          font-size: 2.5rem;
          line-height: 2.5rem;
          margin-bottom: 1rem;
        }
      }
      &__description {
        color: var(--primary-color);
        margin-bottom: 2rem;
        width: 300px;
        min-height: 100px;
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 570px) {
    .services {
      margin: 2rem 0;
      grid-template-columns: 1fr;
      gap: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      /* padding: 0 rem; */
      &__service {
        padding: 0.6rem;
      }
    }
  }
`;

export default Services;
