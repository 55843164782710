import React from 'react';
import Slider from "react-slick";
import { useScroll } from "components/useScroll";
import { motion } from "framer-motion";
import { servicesAnimations } from "animation";
import { useQuery, gql } from "@apollo/client";

import  Loading from './Loading'


const PARTNER_QUERY = gql`
{
partners(first:100){
  edges{
    node{
      partnerMeta{
        logo{
          sourceUrl
        }
      }
    }
  }
}
}
` 

function 
Partners() {
  const [element, controls] = useScroll();
  const { data, loading, error } = useQuery(PARTNER_QUERY);
  if (loading) return <Loading />;
  if (error) return <pre>{error.message}</pre>
  const partnersList = data.partners.edges
  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    // initialSlide: 0,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          autoplaySpeed: 100,
          speed: 5000,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          autoplaySpeed: 200,
          speed: 5000,

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplaySpeed: 400,
        }
      }
    ]
  };

  return <div id="services" ref={element} className='overflow-y-hidden'>
    <div className="">
              <motion.div className=""
              variants={servicesAnimations}
              animate={controls}
              transition={{
                  delay: 0.03,
                  type: "tween",
                  duration: 0.8,
                }}
              >
                <h2 className='text-5xl text-primary pt-4 pl-7 font-primary'>Partners</h2>
                  <div className="items-center overflow-x-hidden">
      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
      />
      <Slider {...settings} className = 'flex items-center pt-10 '>

      {partnersList.map((partner)=>(
        <div>
          <img src={partner.node.partnerMeta.logo.sourceUrl} className="w-36" alt=""/>
        </div>

      ))}       
      </Slider>
    </div>
              </motion.div>
           
    </div>
  </div>
}

export default Partners